import {
  Grid,
  Box,
  styled,
  Typography,
  autocompleteClasses,
  Popover,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Stack,
  Button,
} from "@mui/material";
import {
  MdHealthAndSafety,
  MdOutlineEmojiTransportation,
  MdAttachMoney,
  MdFastfood,
  MdOutlineMovie,
} from "react-icons/md";
import React, { useState } from "react";
import {
  FaHamburger,
  FaHome,
  FaMusic,
  FaShapes,
  FaShoppingBag,
  FaGift,
} from "react-icons/fa";
import useResponsive from "../../theme/hooks/useResponsive";
import PropTypes from "prop-types";
import {
  convertToCurrency,
  currencyFind,
  getMonthMMM,
} from "../../utils/helper";
import Iconify from "../Iconify";
import { Link as RouterLink } from "react-router-dom";
import dataConfig from "../../config.json";
import { deleteIncomeService } from "../../services/incomeServices";

// const DateBoxStyle = styled("div")(({ theme }) => ({
//   width: 70,
//   height: 70,
//   borderRadius: 50,
//   padding: 5,
//   background: theme.palette["warning"].lighter,
//   color: theme.palette["warning"].darker,
// }));

const IconBox = styled("div")(({ theme }) => ({
  width: 60,
  height: 60,
  borderRadius: 50,
  padding: 5,
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  //   color: theme.palette["warning"].darker,
}));

IncomeCard.propTypes = {
  incomeName: PropTypes.string,
  incomeAmount: PropTypes.number,
  incomePerMember: PropTypes.number,
  incomeOwner: PropTypes.string,
  currencyType: PropTypes.string,
  incomeCategory: PropTypes.string,
};

const modelStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
};

export default function IncomeCard({
  incomeId,
  incomeName,
  incomeAmount,
  incomePerMember,
  incomeOwner,
  incomeDate,
  incomeCategory,
  currencyType,
}) {
  const mdUp = useResponsive("up", "md");
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteConfirmOpen = () => {
    setDeleteConfirm(true);
  };
  const deleteConfirmClose = () => {
    setDeleteConfirm(false);
  };

  const apiDeleteCall = async () => {
    await deleteIncomeService({ id: incomeId });
    window.location.reload();
    deleteConfirmClose();
  };

  const getIcons = (type) => {
    switch (type) {
      case "food-drink":
        return <MdFastfood />;
      case "shopping":
        return <FaShoppingBag />;
      case "entertainment":
        return <MdOutlineMovie />;
      case "home":
        return <FaHome />;
      case "others":
        return <FaShapes />;
      case "health":
        return <MdHealthAndSafety />;
      case "gifts":
        return <FaGift />;
      case "transportation":
        return <MdOutlineEmojiTransportation />;
      case "wages":
        return <MdAttachMoney />;
      default:
        return "";
    }
  };

  const getIconsColor = (type) => {
    switch (type) {
      case "food-drink":
        return "#ffeced";
      case "shopping":
        return "#f4f4f4";
      case "entertainment":
        return "#ffeef5";
      case "home":
        return "#ebf9ff";
      case "others":
        return "#fff4ec ";
      case "health":
        return "#effcf4";
      case "gifts":
        return "#ffeced";
      case "transportation":
        return "#ebf8ff";
      case "wages":
        return "#fff0d9";
      default:
        return "";
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      sx={{
        boxShadow: 5,
        borderRadius: 1,
        pl: 1,
        py: 1,
      }}
    >
      <Grid item xs={2}>
        {/* <DateBoxStyle incomeCategory={incomeCategory} /> */}
        <IconBox
          style={{
            backgroundColor: getIconsColor(incomeCategory),
          }}
        >
          <div
            style={{
              width: "40px",
            }}
          >
            {getIcons(incomeCategory)}
          </div>
        </IconBox>
      </Grid>
      <Grid item xs={5} ml={1}>
        <Typography
          fontFamily="Shan"
          noWrap
          variant="h6"
          color={(theme) => theme.palette["primary"].dark}
        >
          {incomeName}
        </Typography>

        <Typography
          noWrap
          variant="body2"
          sx={{
            fontSize: 9,
          }}
        >
          <b>
            {" "}
            {getMonthMMM(incomeDate)} {new Date(incomeDate).getDate().zeroPad()}
          </b>
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography
          fontFamily="Shan"
          align={"right"}
          color={(theme) => theme.palette["success"].dark}
        >
          <b>
            {currencyFind(currencyType)} {convertToCurrency(incomeAmount)}
          </b>
        </Typography>
        {/* <Typography fontSize={"12px"} align={"right"}>
          {incomeCategory}
        </Typography> */}
      </Grid>
      <Grid item xs={1}>
        <Box
          sx={{
            p: 0,
            mt: -3.8,
          }}
        >
          <Iconify
            aria-describedby={id}
            icon="charm:menu-meatball"
            onClick={handleClick}
          />
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <MenuList>
              <MenuItem
                component={RouterLink}
                to={dataConfig.VIEW_INCOME_URL + incomeId}
              >
                <ListItemIcon>
                  <Iconify icon="carbon:view-filled" />
                </ListItemIcon>
                <ListItemText>View</ListItemText>
              </MenuItem>
              <MenuItem
                component={RouterLink}
                to={dataConfig.EDIT_INCOME_URL + incomeId}
              >
                <ListItemIcon>
                  <Iconify icon="dashicons:edit-large" />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={deleteConfirmOpen}
                sx={{ color: (theme) => theme.palette["error"].main }}
              >
                <ListItemIcon>
                  <Iconify
                    sx={{ color: (theme) => theme.palette["error"].main }}
                    icon="fluent:delete-20-filled"
                  />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
              </MenuItem>
            </MenuList>
          </Popover>
          <Modal
            open={deleteConfirm}
            onClose={deleteConfirmClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={modelStyle} width={mdUp ? 400 : "90%"}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Confirm income deletion
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Are you sure you want to delete the income?
              </Typography>
              <Stack mt={2} spacing={2} direction={"row"}>
                <Button
                  startIcon={<Iconify icon="fluent:delete-dismiss-24-filled" />}
                  variant="outlined"
                  color="error"
                  sx={{ width: "100%" }}
                  onClick={apiDeleteCall}
                >
                  Delete
                </Button>
                <Button
                  startIcon={<Iconify icon="material-symbols:cancel" />}
                  variant="outlined"
                  color="primary"
                  sx={{ width: "100%" }}
                  onClick={deleteConfirmClose}
                >
                  Cancel
                </Button>
              </Stack>
            </Box>
          </Modal>
        </Box>
      </Grid>
    </Grid>
  );
}
