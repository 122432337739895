import { LoadingButton } from "@mui/lab";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import {
  Box,
  Button,
  Chip,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import useResponsive from "../../theme/hooks/useResponsive";
import { currencyFind } from "../../utils/helper";
import {
  editIncomeService,
  getIncomeDetailsService,
} from "../../services/incomeServices";
import { useParams } from "react-router-dom";
import { getGroupDetailsService } from "../../services/groupServices";
import Loading from "../loading";
import { useNavigate } from "react-router-dom";
import AlertBanner from "../AlertBanner";

export default function EditIncome() {
  const navigate = useNavigate();
  const params = useParams();
  const mdUp = useResponsive("up", "md");
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  var [groupMembers, setGroupMembers] = useState();
  const [incomeDetails, setIncomeDetails] = useState();

  //Formink schema
  const editIncomeSchema = Yup.object().shape({
    incomeName: Yup.string().required("Income name is required"),
    incomeDescription: Yup.string(),
    incomeAmount: Yup.string().required("Amount is required"),
    incomeCategory: Yup.string().required("Category is required"),
    incomeType: Yup.string().required("Payment Method is required"),
  });

  const formik = useFormik({
    initialValues: {
      incomeName: null,
      incomeDescription: null,
      incomeAmount: null,
      incomeCategory: null,
      incomeDate: null,
      incomeMembers: null,
      incomeOwner: null,
      groupId: null,
      incomeType: null,
      id: null,
    },
    validationSchema: editIncomeSchema,
    onSubmit: async () => {
      setLoading(true);
      if (await editIncomeService(values, setAlert, setAlertMessage))
        navigate(-1);
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    const getIncomeDetails = async () => {
      setLoading(true);
      const incomeIdJson = {
        id: params.incomeId,
      };
      const response_exp = await getIncomeDetailsService(
        incomeIdJson,
        setAlert,
        setAlertMessage
      );
      setIncomeDetails(response_exp?.data?.income);
      const exp = response_exp?.data?.income;

      const groupIdJson = {
        id: response_exp?.data?.income?.groupId,
      };
      const response_group = await getGroupDetailsService(
        groupIdJson,
        setAlert,
        setAlertMessage
      );
      formik.values.incomeName = exp?.incomeName;
      formik.values.incomeDescription = exp?.incomeDescription;
      formik.values.incomeOwner = exp?.incomeOwner;
      formik.values.incomeMembers = exp?.incomeMembers;
      formik.values.incomeAmount = exp?.incomeAmount;
      formik.values.incomeCategory = exp?.incomeCategory;
      formik.values.incomeDate = exp?.incomeDate;
      formik.values.groupId = exp?.groupId;
      formik.values.incomeType = exp?.incomeType;
      formik.values.id = exp?._id;
      setGroupMembers(response_group?.data?.group?.groupMembers);
      setLoading(false);
    };
    getIncomeDetails();
  }, []);
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Box
          sx={{
            position: "relative",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            ...(mdUp && { width: 700 }),
          }}
        >
          <AlertBanner
            showAlert={alert}
            alertMessage={alertMessage}
            severity="error"
          />
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
            Edit Income
          </Typography>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={3} sx={{ maxWidth: 800 }}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="text"
                    name="incomeName"
                    id="outlined-basic"
                    label="Income Name"
                    variant="outlined"
                    {...getFieldProps("incomeName")}
                    error={Boolean(touched.incomeName && errors.incomeName)}
                    helperText={touched.incomeName && errors.incomeName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    multiline
                    rows={2}
                    fullWidth
                    name="incomeDescription"
                    id="outlined-basic"
                    label="Income Description"
                    variant="outlined"
                    {...getFieldProps("incomeDescription")}
                    error={Boolean(
                      touched.incomeDescription && errors.incomeDescription
                    )}
                    helperText={
                      touched.incomeDescription && errors.incomeDescription
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.incomeOwner && errors.incomeOwner)}
                  >
                    <InputLabel id="income-owner">Income Owner</InputLabel>
                    <Select
                      name="incomeOwner"
                      labelId="income-owner"
                      id="demo-simple-select"
                      label="Income Owner"
                      {...getFieldProps("incomeOwner")}
                    >
                      {groupMembers?.map((member) => (
                        <MenuItem key={member} value={member}>
                          {member}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {touched.incomeOwner && errors.incomeOwner}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="income-members-label">
                      Income Members
                    </InputLabel>
                    <Select
                      labelId="income-members-label"
                      id="income-members"
                      multiple
                      {...getFieldProps("incomeMembers")}
                      input={
                        <OutlinedInput
                          id="income-members"
                          label="Income Members"
                        />
                      }
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {groupMembers?.map((member) => (
                        <MenuItem key={member} value={member}>
                          {member}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    name="incomeAmount"
                    id="outlined-basic"
                    type="number"
                    label="Income Amount"
                    variant="outlined"
                    {...getFieldProps("incomeAmount")}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {currencyFind(incomeDetails?.incomeCurrency)}
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(touched.incomeAmount && errors.incomeAmount)}
                    helperText={touched.incomeAmount && errors.incomeAmount}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    error={Boolean(
                      touched.incomeCategory && errors.incomeCategory
                    )}
                  >
                    <InputLabel id="income-category">
                      Income Category
                    </InputLabel>
                    <Select
                      name="incomeCategory"
                      labelId="income-category"
                      id="demo-simple-select"
                      label="Income Category"
                      {...getFieldProps("incomeCategory")}
                    >
                      <MenuItem value={"Food & drink"}>Food & drink</MenuItem>
                      <MenuItem value={"Shopping"}>Shopping</MenuItem>
                      <MenuItem value={"Entertainment"}>Entertainment</MenuItem>
                      <MenuItem value={"Home"}>Home</MenuItem>
                      <MenuItem value={"Transportation"}>
                        Transportation
                      </MenuItem>
                      <MenuItem value={"Others"}>Others</MenuItem>
                    </Select>
                    <FormHelperText>
                      {touched.incomeCategory && errors.incomeCategory}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    error={Boolean(
                      touched.incomeCategory && errors.incomeCategory
                    )}
                  >
                    <InputLabel id="income-type">Payment Method</InputLabel>
                    <Select
                      name="incomeType"
                      labelId="income-type"
                      id="demo-simple-select"
                      label="Payment Method"
                      {...getFieldProps("incomeType")}
                    >
                      <MenuItem value={"Cash"}>Cash</MenuItem>
                      <MenuItem value={"UPI Payment"}>UPI Payment</MenuItem>
                      <MenuItem value={"Card"}>Card</MenuItem>
                    </Select>
                    <FormHelperText>
                      {touched.incomeType && errors.incomeType}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    {mdUp ? (
                      <DesktopDatePicker
                        name="incomeDate"
                        label="Income Date"
                        inputFormat="dd/MM/yyyy"
                        renderInput={(params) => (
                          <TextField {...params} sx={{ width: "100%" }} />
                        )}
                        value={formik.values.incomeDate}
                        onChange={(value) => {
                          formik.setFieldValue("incomeDate", Date.parse(value));
                        }}
                      />
                    ) : (
                      <MobileDatePicker
                        name="incomeDate"
                        label="Income Date"
                        inputFormat="dd/MM/yyyy"
                        renderInput={(params) => (
                          <TextField {...params} sx={{ width: "100%" }} />
                        )}
                        value={formik.values.incomeDate}
                        onChange={(value) => {
                          formik.setFieldValue("incomeDate", Date.parse(value));
                        }}
                      />
                    )}
                  </LocalizationProvider>
                </Grid>

                {mdUp && <Grid item xs={0} md={6} />}
                <Grid item xs={6} md={3}>
                  <Button
                    fullWidth
                    size="large"
                    variant="outlined"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6} md={3}>
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    Edit
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </Box>
      )}
    </>
  );
}
