export const categoryOptions = [
  {
    label: "Food & drink",
    value: "food-drink",
  },
  {
    label: "Education",
    value: "education",
  },
  {
    label: "Shopping",
    value: "shopping",
  },
  {
    label: "Entertainment",
    value: "entertainment",
  },
  {
    label: "Home",
    value: "home",
  },
  {
    label: "Others",
    value: "others",
  },
  {
    label: "Health",
    value: "health",
  },
  {
    label: "Gifts",
    value: "gifts",
  },
  {
    label: "Transportation",
    value: "transportation",
  },
  {
    label: "Wages",
    value: "wages",
  },
];
