import * as api from "../api/index";

export const addIncomeService = async (data, setAlert, setAlertMessage) => {
  try {
    const add_exp_response = await api.addIncome(data);
    return add_exp_response;
  } catch (err) {
    setAlert(true);
    err.response.status === 400 || err.response.status === 401
      ? setAlertMessage(err.response.data.message)
      : setAlertMessage("Oops! Something went worng");
    return false;
  }
};

export const getGroupCategoryIncomeService = async (
  data,
  setAlert,
  setAlertMessage
) => {
  try {
    return await api.getGroupCategoryIncome(data);
  } catch (err) {
    setAlert(true);
    err.response.status === 400 || err.response.status === 401
      ? setAlertMessage(err.response.data.message)
      : setAlertMessage("Oops! Something went worng");
    return false;
  }
};
export const getGroupMonthlyIncomeService = async (
  data,
  setAlert,
  setAlertMessage
) => {
  try {
    return await api.getGroupMonthlyIncome(data);
  } catch (err) {
    setAlert(true);
    err.response.status === 400 || err.response.status === 401
      ? setAlertMessage(err.response.data.message)
      : setAlertMessage("Oops! Something went worng");
    return false;
  }
};

export const getGroupDailyIncomeService = async (
  data,
  setAlert,
  setAlertMessage
) => {
  try {
    return await api.getGroupDailyIncome(data);
  } catch (err) {
    setAlert(true);
    err.response.status === 400 || err.response.status === 401
      ? setAlertMessage(err.response.data.message)
      : setAlertMessage("Oops! Something went worng");
    return false;
  }
};

export const getUserIncomeService = async (data, setAlert, setAlertMessage) => {
  try {
    const income_details = await api.getUserIncome(data);
    return income_details;
  } catch (err) {
    setAlert(true);
    err.response.status === 400 || err.response.status === 401
      ? setAlertMessage(err.response.data.message)
      : setAlertMessage("Oops! Something went worng");
    return false;
  }
};

export const getUserMonthlyIncomeService = async (
  data,
  setAlert,
  setAlertMessage
) => {
  try {
    return await api.getUserMonthlyIncome(data);
  } catch (err) {
    setAlert(true);
    err.response.status === 400 || err.response.status === 401
      ? setAlertMessage(err.response.data.message)
      : setAlertMessage("Oops! Something went worng");
    return false;
  }
};

export const getUserDailyIncomeService = async (
  data,
  setAlert,
  setAlertMessage
) => {
  try {
    return await api.getUserDailyIncome(data);
  } catch (err) {
    setAlert(true);
    err.response.status === 400 || err.response.status === 401
      ? setAlertMessage(err.response.data.message)
      : setAlertMessage("Oops! Something went worng");
    return false;
  }
};

export const getUserCategoryIncomeService = async (
  data,
  setAlert,
  setAlertMessage
) => {
  try {
    return await api.getUserCategoryIncome(data);
  } catch (err) {
    setAlert(true);
    err.response.status === 400 || err.response.status === 401
      ? setAlertMessage(err.response.data.message)
      : setAlertMessage("Oops! Something went worng");
    return false;
  }
};

export const getRecentUserIncomeService = async (
  data,
  setAlert,
  setAlertMessage
) => {
  try {
    return await api.getRecentUserIncome(data);
  } catch (err) {
    setAlert(true);
    err.response.status === 400 || err.response.status === 401
      ? setAlertMessage(err.response.data.message)
      : setAlertMessage("Oops! Something went worng");
    return false;
  }
};

export const getIncomeDetailsService = async (
  data,
  setAlert,
  setAlertMessage
) => {
  try {
    return await api.getIncomeDetails(data);
  } catch (err) {
    setAlert(true);
    err.response.status === 400 || err.response.status === 401
      ? setAlertMessage(err.response.data.message)
      : setAlertMessage("Oops! Something went worng");
    return false;
  }
};

export const editIncomeService = async (data, setAlert, setAlertMessage) => {
  try {
    const edit_exp_response = await api.editIncome(data);
    return edit_exp_response;
  } catch (err) {
    setAlert(true);
    err.response.status === 400 || err.response.status === 401
      ? setAlertMessage(err.response.data.message)
      : setAlertMessage("Oops! Something went worng");
    return false;
  }
};

export const deleteIncomeService = async (data, setAlert, setAlertMessage) => {
  try {
    const delete_exp_response = await api.deleteIncome(data);
    return delete_exp_response;
  } catch (err) {
    setAlert(true);
    err.response.status === 400 || err.response.status === 401
      ? setAlertMessage(err.response.data.message)
      : setAlertMessage("Oops! Something went worng");
    return false;
  }
};
