import PropTypes from "prop-types";
import { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import {
  Box,
  Link,
  Button,
  Drawer,
  Typography,
  Avatar,
  Stack,
} from "@mui/material";
import gravatarUrl from "gravatar-url";

// hooks
import useResponsive from "../../theme/hooks/useResponsive";
// components
import Logo from "../../components/Logo";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
//
import navConfig from "./NavConfig";
import Copyright from "../../components/Copyright";

import dataConfig from "../../config.json";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.primary["lighter"],
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const user = JSON.parse(localStorage.getItem("profile"));

  const { pathname } = useLocation();

  const isDesktop = useResponsive("up", "lg");

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <>
      <Scrollbar
        sx={{
          height: 1,
          "& .simplebar-content": {
            height: 1,
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <Box sx={{ px: 2.5, py: 3, display: "inline-flex" }}>
          <Logo />
        </Box>

        {/* <Box sx={{ mb: 5, mx: 2.5, mt: 5 }}>
          <Link
            underline="none"
            component={RouterLink}
            to={dataConfig.USER_PROFILE_URL}
          >
            <AccountStyle>
              {user && (
                <Avatar
                  src={gravatarUrl(user?.emailId, {
                    size: 200,
                    default: dataConfig.USER_DEFAULT_LOGO_URL,
                  })}
                  alt="photoURL"
                />
              )}
              <Box sx={{ ml: 2 }}>
                <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                  {user?.firstName} {user?.lastName}
                </Typography>
                <Typography
                  variant="span"
                  fontSize={12}
                  sx={{ color: "text.secondary" }}
                >
                  {user?.emailId}
                </Typography>
              </Box>
            </AccountStyle>
          </Link>
        </Box> */}

        <NavSection navConfig={navConfig} />

        <Box sx={{ flexGrow: 1 }} />
      </Scrollbar>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ marginTop: "auto", pb: 2 }}>
          <Copyright />
        </Box>
      </Box>
    </>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
              borderRightStyle: "none",
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
